export default [
  {
    title: "Start Date",
    key: "LeaveStartDate",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "End Date",
    key: "LeaveEndDate",
    sortable: true,
    minWidth: 80,
  },

  {
    title: "Start Time",
    key: "StartTime",
    // sortable: true,
    minWidth: 80,
  },
  {
    title: "End Time",
    key: "EndTime",
    // sortable: true,
    minWidth: 80,
  },
];
